import React, { useState, useEffect, useRef } from 'react';
import { IFilm } from '../../Interfaces/Film';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Settings from "../../Utils/Routes.json";

import SecurityIcon from '@mui/icons-material/Security';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';

import './NewNavbar.css';

// Interface

import { Avatar, Box, Menu, MenuItem, TextField, Tooltip, Typography, IconButton } from '@mui/material';
import { useAuth } from '../../Components/AuthProvider';
import { searchFilmDataRequest } from '../../Utils/Requests/RequestRegister';

import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';

interface ISettings {
    display: string;
    redirect: string;
    icon?: React.ReactElement<any>;
    action?: () => void | undefined;
}

function NewNavbar() {
    const { user, logOutUser } = useAuth();
    const searchResultEl = useRef<HTMLDivElement | null>(null);
    const formRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const { pathname } = location;
    const navigation = useNavigate()

    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [activeTabPosition, setActiveTabPosition] = useState<{ top: number, left: number, width: number, height: number }>({ top: 0, left: 0, width: 0, height: 0 });

    const [userSearching, setUserSearching] = useState<boolean>(false);
    const [filmList, setFilmList] = useState<IFilm[]>([])
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null); // State for the timeout

    const navbarList = [
        {
            url: "/",
            name: "Home"
        },
        {
            url: "/films",
            name: "Films"
        },
        {
            url: "/series",
            name: "Series"
        },
        {
            url: "/actors",
            name: "Actors"
        },
        {
            url: "/directors",
            name: "Directors"
        },
    ]

    const [settings, setSettings] = useState<ISettings[]>([
        {
            display: 'Settings',
            redirect: "/user/settings",
            icon: <SettingsIcon className='settings-icon' />
        },
        {
            display: "History",
            redirect: "/user/history",
            icon: <HistoryIcon className='settings-icon' />
        },
        {
            display: "Logout",
            redirect: "/",
            action: logOutUser,
            icon: <LogoutIcon className='settings-icon' />
        }
    ]);


    const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.value.length < 2) {
            setFilmList([])
            return
        }

        // Clear any existing debounce timeout before setting a new one
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(async () => {
            let searchParams = `?film_name=${e.target.value}&page=1&limit=10`;

            await searchFilmDataRequest(searchParams)
                .then(data => {
                    if (data.status === "error") {
                        setFilmList([])
                        return
                    }

                    if (data.total === 0) {
                        setFilmList([])
                        return
                    }

                    setFilmList(data.films)
                })
                .catch(error => {
                    setFilmList([])
                    return
                })
        }, 500); // Delay the request for 500ms (you can adjust this time as needed)

        setDebounceTimeout(newTimeout);
    };

    const activeSearch = (action: boolean) => {
        setUserSearching(action);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        activeSearch(false)
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                activeSearch(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [userSearching]);

    useEffect(() => {
        if (user.loaded === false || user.accountStatus === "Admin") return;
      
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//chancesarmlessimpulse.com/1e/e7/58/1ee7581bd5dcb9e02c44a68e4d2fc7e5.js';
      
        document.head.appendChild(script);
      
        // Cleanup: remove script on component unmount
        return () => {
          if (script) document.head.removeChild(script);
        };
      }, [user.loaded, user.accountStatus]);
      


    return (
        <>
            <div className='navbar'>
                <div className='container'>
                    <div className='navbar-holder'>
                        <div className='navbar-buttons'>
                            <img src='/logo192.png' alt="Logo" style={{cursor: "pointer"}} onClick={() => {
                                navigation("/");
                            }}/>
                            < MenuIcon className='menu-toggle' onClick={() => {
                                setMenuOpen(true)
                            }}></MenuIcon>
                            <ul className='navbar-button-list'>
                                {navbarList.map((navItem, index) => (
                                    <li key={index} className='navbar-button'>
                                        <Link to={navItem.url}>{navItem.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='navbar-search'>
                            <button className='film-search-button' onClick={() => activeSearch(true)}>
                                <SearchIcon />
                            </button>
                            {user.loggedIn ? (
                                <Box sx={{ flexGrow: 0, marginLeft: 5 }}>
                                    <Tooltip title="Account">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} >
                                            <Avatar
                                                alt={user.firstName + " " + user.lastName}
                                                sx={{
                                                    width: 48, // Set the width to your desired size
                                                    height: 48, // Set the height to your desired size
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '55px', }}
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {user.accountStatus === "Admin" && (
                                            <MenuItem key={"admin"} onClick={(e) => {
                                                navigation("admin");
                                                handleCloseUserMenu();
                                            }}>
                                                <SecurityIcon className='settings-icon' />
                                                <Typography textAlign="end" sx={{ color: "white" }}>{" Admin"}</Typography>
                                            </MenuItem>
                                        )}
                                        {settings.map((setting) => (
                                            <MenuItem key={setting.display} onClick={(e) => {
                                                setting.action && setting.action();

                                                if (setting.redirect) {
                                                    navigation(setting.redirect);
                                                } else {
                                                    window.location.href = setting.redirect;
                                                }

                                                handleCloseUserMenu();
                                            }}>
                                                {setting.icon}
                                                <Typography textAlign="end" sx={{ color: "white" }}>{setting.display}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            ) : (
                                <Link className='sign-in-button' to={"auth/login"}>
                                    <LoginIcon />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={'search-promp-holder ' + (userSearching ? "active" : "")}
                style={{ display: userSearching ? "flex" : "none" }}
            >
                <div className='search-film-form' ref={formRef}>
                    <div className='search-input'>
                        <SearchIcon />
                        <input
                            type="text"
                            placeholder='Search for a film'
                            className='search-film-input'
                            onChange={onSearch}
                        />
                    </div>
                    <div className='film-list-holder' style={{ display: (filmList.length === 0 ? "none" : "flex") }}>
                        <ul className='film-list-data'>
                            {filmList.map((film, index) => (
                                <Link to={"film/" + film.id} key={index} className='film-list-item'>
                                    <img className='film-poster' src={Settings.Domain + Settings.imagesFilm + film.film_poster}></img>
                                    <div className='film-list-item-data'>
                                        <h2 className='film-title'>{film.film_name}</h2>
                                        <p className='film-description'>{film.film_description}</p>
                                    </div>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className={'navbar-menu ' + (menuOpen === true ? "active" : "")}>
                <ul className='navbar-button-list'>
                    {navbarList.map((navItem, index) => (
                        <li key={index} className='navbar-button'>
                            <Link to={navItem.url}
                                onClick={() => {
                                    setMenuOpen(false)
                                }}
                            >{navItem.name}</Link>
                        </li>
                    ))}
                </ul>
                <ClearIcon className='close-icon-navabr' onClick={() => {
                    setMenuOpen(false)
                }}></ClearIcon>
            </div>
        </>
    );
}

export default NewNavbar;
