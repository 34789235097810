import React, { useEffect, useState } from 'react';
import './Banner.css';

import FilmCards from '../FilmCard/FilmCard';
import { useDragScroll } from '../../Components/Dragger';
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { IFilm } from '../../Interfaces/Film';

import Settings from "../../Utils/Routes.json";
import CircularProgress from '../../Components/CircularProgress';
import { getCountries } from '../../Utils/Requests/RequestRegister';
import { ICountry } from '../../Interfaces/Country';
import FilmScoreDesign from '../FilmScoreDesign/FilmScoreDesign';

const scoreSize = 48
const scoreThickness = 4

function Banner() {
    const [hoveredFilm, setHoveredFilm] = useState<IFilm | undefined>();
    const [backgroundImage, setBackgroundImage] = useState<string>('');
    const [countries, setCountries] = useState<ICountry[]>([]);

    const filmData: ISearchFilm = {
        sort: "new",
        page: 1,
        limit: 9,
        skeletonAmount: 9,
    };


    const [draggerRef] = useDragScroll();

    const loadCountries = async () => {
        try {
            const response = await getCountries();

            if (response.status === "error") {
                console.error('Error loading countries:', response.message);
                return;
            }

            const countries: ICountry[] = response.countries;

            setCountries(response.countries)
        } catch (error) {
            console.error('Error loading countries:', error);
        }
    };

    const getFilmCountry = (countryId: number | undefined): string => {
        if (!countryId) {
            return '';
        }

        const country: ICountry | undefined = countries.find((country) => country.id == countryId);

        if (!country) {
            return ""
        }

        return country.country_name
    };

    const handleFilmHover = (film: IFilm) => {
        setBackgroundImage(`url(${Settings.Domain + Settings.imagesFilm + film.film_banner})`);
        setHoveredFilm(film);
    };

    useEffect(() => {
        loadCountries()
    }, [filmData])

    return (
        <div className='banner-skeleton' style={{ backgroundImage: hoveredFilm ? backgroundImage : "", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className='spacer'></div>

            <div className='content'>
                <div className='film-info'>
                    <h2 className='film-title'>
                        {hoveredFilm?.film_name}
                        <span style={{ color: "gray" }}> ({hoveredFilm?.film_release})</span>
                    </h2>

                    <div className='film-score'>
                        <FilmScoreDesign
                            like={hoveredFilm?.like}
                            dislike={hoveredFilm?.dislike}
                            size={scoreSize} // Optional: override size
                            thickness={scoreThickness} // Optional: override thickness
                            sx={{
                                position: "inherit"
                            }}
                        />
                    </div>

                    <p className='film-country' style={{ color: "gray" }}>{getFilmCountry(hoveredFilm?.film_country)}</p>
                    
                </div>
                <div className='film-list' ref={draggerRef}>
                    <FilmCards filmData={filmData} horizontal={true} className="banner" onHover={handleFilmHover} play={true} speed={5000} />
                </div>
            </div>
            <div className='shadow'></div>
        </div>
    )
}

export default Banner;
