import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../Design/Button/Button";
import { Helmet } from "react-helmet";
import "./Auth.css";

// Icons
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from "../../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../../Utils/Requests/RequestRegister";
import { ISnackbar } from "../../Interfaces/Snackbar";
import { CustomizedSnackbars } from "../../Design/Snackbar/Snackbar";

export default function ResetPassword() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        email: "",
    });

    const [snackBarData, setSnackBarData] = useState<ISnackbar>({
        message: '',
        messageType: '',
        openSnackbar: false,
        key: 0
    });

    const [fieldErrors, setFieldErrors] = useState({
        email: ''
    });

    const [activeInput, setActiveInput] = useState<string | null>(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const resetPasswordAction = async (e: React.MouseEvent, callback: () => void) => {
        e.preventDefault();
        setFieldErrors({ email: '' }); // Reset previous errors

        try {
            const response = await resetPasswordRequest(userData);
            if (response.status === "error") {
                if (response.details) {
                    setFieldErrors(response.details); // Set field-specific errors
                }
                setSnackBarData({
                    message: response.message,
                    messageType: "error",
                    openSnackbar: true,
                    key: new Date().getTime(),
                });
            } else if (response.status === "success") {
                setSnackBarData({
                    message: response.message,
                    messageType: "success",
                    openSnackbar: true,
                    key: new Date().getTime(),
                });
            }
        } catch (error) {
            console.error(error);
            setSnackBarData({
                message: "An unexpected error occurred. Please try again.",
                messageType: "error",
                openSnackbar: true,
                key: new Date().getTime(),
            });
        }
        callback();
    };

    const handleFocus = (inputName: string) => {
        setActiveInput(inputName);
    };

    useEffect(() => {
        if (user.loggedIn) {
            navigate("/");
        }
    }, [user.loggedIn, navigate]);

    return (
        <>
            <Helmet>
                <title>Reset Password</title>
                <meta name="title" content="Reset Password" />
                <meta name="description" content="Reset your FxFilms password" />
                <meta name="keywords" content="reset password, fxfilms, free films, password recovery" />
                <meta property="og:title" content="Reset Password" />
                <meta property="og:description" content="Reset your FxFilms password to continue enjoying free films." />
            </Helmet>

            <div className="auth-form-holder">
                <form className="login-form">
                    <h1>Reset Password</h1>

                    <TextField
                        label="Email"
                        fullWidth
                        variant="standard"
                        required
                        InputProps={{
                            startAdornment: (
                                <EmailIcon sx={{ margin: "0 10px 0 0", color: activeInput === "email" ? "var(--primaryColor)" : "inherit" }} />
                            ),
                        }}
                        type="email"
                        name="email"
                        onChange={onChange}
                        onFocus={() => handleFocus("email")}
                        error={!!fieldErrors.email}
                        helperText={fieldErrors.email?.[0] || ""}
                    />

                    <Button
                        text="Send Reset Link"
                        clicked={resetPasswordAction}
                        icon={undefined}
                    />
                </form>
            </div>
            <CustomizedSnackbars
                messageType={snackBarData.messageType}
                message={snackBarData.message}
                openSnackbar={snackBarData.openSnackbar}
                key={snackBarData.key}
            />
        </>
    );
}
