import React, { useState, useEffect, useRef } from 'react';
import { IFilm } from '../../Interfaces/Film';
import { Skeleton } from '@mui/material';
import './FilmCards.css';
import Routes from "../../Utils/Routes.json";
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { searchFilmDataRequest } from '../../Utils/Requests/RequestRegister';
import { Link } from 'react-router-dom';
import { NumberFormat } from '../../Components/NumberFormat';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FilmScoreDesign from "../FilmScoreDesign/FilmScoreDesign";

interface IFilmCardsProps {
    filmData?: ISearchFilm;
    horizontal: boolean;
    className?: string;
    onHover?: (film: IFilm) => void;
    films?: IFilm[];
    continueLoading?: boolean;
    type?: string;
    play?: boolean;
    speed?: number;
}

const scoreSize = 48;
const scoreThickness = 4;

function FilmCards({
    filmData,
    horizontal,
    className,
    onHover,
    films,
    continueLoading,
    type,
    play,
    speed,
}: IFilmCardsProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [filmList, setFilmList] = useState<IFilm[]>([]);
    const [hoveredFilm, setHoveredFilm] = useState<number>(0);
    const imageRefs = useRef<(HTMLImageElement | null)[]>([]);

    const searchFilm = async () => {
        if (!filmData) {
            return;
        }

        let searchParams = '';
        for (const key in filmData) {
            if (Object.prototype.hasOwnProperty.call(filmData, key)) {
                const value = filmData[key as keyof ISearchFilm]; // Type assertion
                if (searchParams.length === 0) {
                    searchParams += `?${key}=${value}`;
                } else {
                    searchParams += `&${key}=${value}`;
                }
            }
        }
        try {
            const searchFilmResponse = await searchFilmDataRequest(searchParams);

            if (searchFilmResponse.status === 'success') {
                if (continueLoading) {
                    setFilmList((prevFilmList) => [
                        ...prevFilmList,
                        ...searchFilmResponse.films,
                    ]);
                } else {
                    setFilmList(searchFilmResponse.films);
                }
            }

            if (searchFilmResponse.films.length > 0) {
                setLoading(false);
            }
        } catch (error) {
            // Handle error if needed
        }
    };

    const handleImageLoad = (index: number) => {
        const imageRef = imageRefs.current[index];
        if (imageRef) {
            // Choose between film_banner and film_poster based on horizontal flag
            const imageUrl = horizontal
                ? Routes.Domain + Routes.imagesFilm + filmList[index].film_banner
                : Routes.Domain + Routes.imagesFilm + filmList[index].film_poster;
            imageRef.src = imageUrl;
        }
    };

    useEffect(() => {
        if (films) {
            setLoading(false);
            return;
        }

        searchFilm();
    }, []);

    useEffect(() => {

        let increment = 0

        if (onHover && filmList.length > 0 && play) {
            onHover(filmList[increment]);
        }

        if (onHover && filmList.length > 0 && play) {
            setInterval(() => {
                if (increment === filmList.length - 1) {
                    increment = 0
                } else {
                    increment = increment + 1
                }

                setHoveredFilm(increment)
                onHover(filmList[increment]);
            }, speed);
        }
    }, [filmList.length]);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = entry.target.getAttribute('data-index');
                        if (index !== null) {
                            handleImageLoad(Number(index));
                        }
                    }
                });
            },
            { threshold: 0.1 }
        );

        imageRefs.current.forEach((img) => {
            if (img) observer.observe(img);
        });

        return () => {
            observer.disconnect();
        };
    }, [filmList]);

    return (
        <>
            {loading ? (
                <>
                    {filmData && [...Array(filmData.skeletonAmount || 20)].map((_, index) => (
                        <Skeleton
                            key={index}
                            className={`${className || "film-skeleton"} ${horizontal ? 'horizontal' : 'vertical'}`}
                            sx={{ background: 'gray' }}
                        />
                    ))}
                </>
            ) : (
                <>
                    {filmList.length > 0 && (
                        filmList.map((film: IFilm, index) => (
                            <>
                                {type === "show_data" ? (
                                    <Link to={{ pathname: `/film/${film.id}` }} state={{ film: film }} key={film.id} className={`film-card  ${horizontal ? 'horizontal' : 'vertical'} ${hoveredFilm == index ? 'hovered' : ''}`} onMouseEnter={() => {
                                        if (onHover) {
                                            onHover(film);
                                            setHoveredFilm(index)
                                        }
                                    }}>
                                        <img
                                            ref={(el) => (imageRefs.current[index] = el)}
                                            data-index={index}
                                            src="" // Initially empty or placeholder
                                            data-src={Routes.Domain + Routes.imagesFilm + film.film_banner} // Actual image URL in data-src
                                            alt={film.film_name}
                                            loading="lazy" // Native lazy loading
                                        />
                                        <div className='watch-pupup'>
                                            <div className='icon-holder'>
                                                <PlayArrowIcon />
                                            </div>
                                        </div>
                                        <span className='film-title'>{film.film_name}</span>
                                        <FilmScoreDesign
                                            like={film.like}
                                            dislike={film.dislike}
                                            size={scoreSize} // Optional: override size
                                            thickness={scoreThickness} // Optional: override thickness
                                        />

                                        <h1 className='film-views'>{film.views ? NumberFormat(film.views) : ""}</h1>
                                    </Link>
                                ) : (
                                    <Link to={{ pathname: `/film/${film.id}` }} state={{ film: film }} key={film.id} className={`film-card  ${horizontal ? 'horizontal' : 'vertical'} ${hoveredFilm == index ? 'hovered' : ''}`} onMouseEnter={() => {
                                        if (onHover) {
                                            onHover(film);
                                            setHoveredFilm(index)
                                        }
                                    }}>
                                        <img
                                            ref={(el) => (imageRefs.current[index] = el)}
                                            data-index={index}
                                            src="" // Initially empty or placeholder
                                            data-src={Routes.Domain + Routes.imagesFilm + film.film_banner} // Actual image URL in data-src
                                            alt={film.film_name}
                                            loading="lazy" // Native lazy loading
                                        />
                                        <span className='film-title'>{film.film_name}</span>
                                        <div className='watch-pupup'>
                                            <div className='icon-holder'>
                                                <PlayArrowIcon />
                                            </div>
                                        </div>
                                        <FilmScoreDesign
                                            like={film.like}
                                            dislike={film.dislike}
                                            size={scoreSize} // Optional: override size
                                            thickness={scoreThickness} // Optional: override thickness
                                        />

                                        <h1 className='film-views'>{film.views ? NumberFormat(film.views) : ""}</h1>
                                    </Link>
                                )}
                            </>
                        ))
                    )}
                    {films && (
                        films.map((filmB: any, index) => {
                            var film: IFilm;

                            if (filmB.film) {
                                film = filmB.film;
                            } else {
                                film = filmB;
                            }

                            return (
                                <Link
                                    to={{ pathname: `/film/${film.id}` }}
                                    state={{ film: film }}
                                    key={film.id}
                                    className="film-card"
                                    onMouseEnter={() => {
                                        onHover && onHover(film);
                                    }}
                                >
                                    <h1 className="film-views">
                                        {film.views ? NumberFormat(film.views) : ""}
                                    </h1>
                                    <img
                                        ref={(el) => (imageRefs.current[index] = el)}
                                        data-index={index}
                                        src={Routes.Domain + Routes.imagesFilm + film.film_banner}
                                        alt={film.film_name}
                                        loading="lazy" // Native lazy loading
                                    />
                                    <span className="film-title">{film.film_name}</span>
                                    <div className="watch-pupup">
                                        <div className="icon-holder">
                                            <PlayArrowIcon />
                                        </div>
                                    </div>
                                    <FilmScoreDesign
                                        like={film.like}
                                        dislike={film.dislike}
                                        size={scoreSize} // Optional: override size
                                        thickness={scoreThickness} // Optional: override thickness
                                    />
                                </Link>
                            );
                        })
                    )}

                </>
            )}
        </>
    );
}

export default FilmCards;