import React, { useEffect, useState } from 'react'

import { getPeopleDataRequest, getCountries } from '../../Utils/Requests/RequestRegister'

import "./People.css"
import { useParams } from 'react-router-dom'
import { IPeople } from '../../Interfaces/People'
import { ICountry } from '../../Interfaces/Country'
import { CustomizedSnackbars } from '../../Design/Snackbar/Snackbar'
import { ISnackbar } from '../../Interfaces/Snackbar'
import Route from "../../Utils/Routes.json"
import FilmCards from '../../Design/FilmCard/FilmCard'
import { IFilm } from '../../Interfaces/Film'
import { Helmet } from 'react-helmet'

export default function People() {

    const { id } = useParams()

    const [people, setPeople] = useState<IPeople | null>(null)
    const [countries, setCountries] = useState<ICountry[]>([])
    const [snackBarData, setSnackBarData] = useState<ISnackbar>({
        message: '',
        messageType: '',
        openSnackbar: false,
        key: 0
    });

    const [films, setFilms] = useState<IFilm[]>([]);


    const loadPerson = async () => {

        if (!id) return

        getPeopleDataRequest("?id=" + id).then(data => {
            if (data.status === "success") {
                setPeople(data.response.response[0])
                setFilms(data.response.response[0].films)
            }

            if (data.status === "error") {
                setSnackBarData({
                    message: data.message,
                    messageType: 'error',
                    openSnackbar: true,
                    key: new Date().getTime()
                })
                setPeople(null)
            }


        }).catch(error => {
            console.error('Error while loading person:', error)
            setSnackBarData({
                message: 'Error while loading person.',
                messageType: 'error',
                openSnackbar: true,
                key: new Date().getTime()
            })
        })
    }

    const loadCounties = async () => {
        getCountries().then(data => {
            if (data.status === "success") {
                setCountries(data.countries)
            }

            if (data.status === "error") {
                setSnackBarData({
                    message: data.message,
                    messageType: 'error',
                    openSnackbar: true,
                    key: new Date().getTime()
                })
                setCountries([])
            }
        }).catch(error => {
            console.error('Error while loading countries:', error)
            setSnackBarData({
                message: 'Error while loading countries.',
                messageType: 'error',
                openSnackbar: true,
                key: new Date().getTime()
            })
            setCountries([])
        })
    }

    const getUserCountry = (countryId: number | undefined): string => {
        console.log(countries);
        console.log(countryId);
        if (!countryId) return ""



        const userCountry = countries.find(c => c.id === countryId)
        return userCountry?.country_name || 'Unknown'
    }

    const calculateUserAge = (born: string) => {
        const data = born.split('-');
        const year = parseInt(data[0]);
        const monthIndex = parseInt(data[1]) - 1; // Months are zero-indexed in JavaScript Date
        const day = parseInt(data[2]);

        const currentDate = new Date();
        const birthDate = new Date(year, monthIndex, day);

        const ageDifMs = currentDate.getTime() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const formattedMonth = monthNames[monthIndex];

        return `${year} ${formattedMonth}, ${day} (${(age)})`
    };



    useEffect(() => {
        loadPerson()
        loadCounties()
    }, [id])

    return (
        <>
            <Helmet>
                <title>Actor</title>
            </Helmet>
            {people && countries.length > 0 && (
                <div className='user-data-holder container'>
                    <div className='user-data-header'>
                        <img className='user-profile-picture' src={Route.Domain + Route.imagesPeople + people?.people_picture}></img>
                        <p className='user-fill-name'>{people?.people_fullname}</p>

                        <div className='user-data'>
                            <p className='user-data-name'>Type: </p>
                            <p className='user-data-value'>{people.people_type}</p>
                        </div>
                    </div>

                    <div className='human-profile-header'>
                        <p>
                            <span>{people?.people_description}</span>
                        </p>
                        <div className='films-list' style={{ flex: "0" }}>
                            <FilmCards films={films} horizontal={true} />
                        </div>
                    </div>

                </div>
            )}
            <CustomizedSnackbars
                messageType={snackBarData.messageType}
                message={snackBarData.message}
                openSnackbar={snackBarData.openSnackbar}
                key={snackBarData.key}
            />
        </>
    )
}
